
import * as React from "react"
import AnswerLayout from "../../../components/answer-layout/answer-layout"

const AnswerPageWrong4 = () => {
  return (
    <AnswerLayout
      step="4"
      backgroundColor="violet"
      contentHTML="
        Oh no, this time your intuition has failed you! A triple divergence is a big sign of a trend reversal, and Bitcoin was rising fast, so it did a reversal and went down. Your buy order generated you a loss
        of <span><span id=&quot;balance-direction&quot;></span>$<span id=&quot;balance-change&quot;></span></span>.
      "
      buttonText="Continue"
      buttonLink="/question/5"
      balanceText="Your Balance:"
      images={{
        map: {
          show: true,
          factor: 0.04
        },
        washingtonTop: {
          show: false,
          factor: 0.08
        },
        dotsLeft: {
          show: true,
          factor: 0.12
        },
        chartDown: {
          show: true,
          factor: 0.16
        },
        dollarSmall: {
          show: true,
          factor: 0.2
        },
        bitcoin: {
          show: true,
          factor: 0.24
        },
        plusesHorizontal: {
          show: false,
          factor: 0.28
        },
        plusesHorizontalGreen: {
          show: true,
          factor: 0.28
        },
        washingtonBottom: {
          show: false,
          factor: 0.32
        },
        dollarBig: {
          show: true,
          factor: 0.36
        },
        plusesVertical: {
          show: false,
          factor: 0.4
        },
        plusesVerticalGreen: {
          show: true,
          factor: 0.4
        },
        dotsRight: {
          show: true,
          factor: 0.4
        },
        chartUp: {
          show: true,
          factor: 0.48
        },
        satoshiTop: {
          show: false,
          factor: 0.08
        },
        satoshiBottom: {
          show: false,
          factor: 0.32
        },
        bankTop: {
          show: false,
          factor: 0.08
        },
        bankBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowGreenRedTop: {
          show: false,
          factor: 0.08
        },
        chartArrowGreenRedBottom: {
          show: false,
          factor: 0.32
        },
        chartArrowGreenTop: {
          show: true,
          factor: 0.08
        },
        chartArrowGreenBottom: {
          show: true,
          factor: 0.32
        },
        chartArrowYellowTop: {
          show: false,
          factor: 0.08
        },
        chartArrowYellowBottom: {
          show: false,
          factor: 0.32
        },
        questionMarkTop: {
          show: false,
          factor: 0.12
        },
        questionMarkBottom: {
          show: false,
          factor: 0.32
        },
        chartGreenUp: {
          show: false,
          factor: 0.48
        },
        chartGreenDown: {
          show: false,
          factor: 0.16
        },
        bitcoinBottom: {
          show: false,
          factor: 0.36
        },
        confetti: {
          show: false,
          factor: 0.01
        }
      }}
    ></AnswerLayout>
  )
}

export default AnswerPageWrong4